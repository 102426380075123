import React, { Component } from "react";
import { fetch } from "fetch-awesome";
import { Platform, Modal, View } from "react-native";
import Constants from "expo-constants";
import * as SecureStore from 'expo-secure-store';
import * as Updates from 'expo-updates';
import ModalWEB from 'modal-react-native-web';
const utf8 = require('utf8');
const base64 = require('base-64');


const timeout = 10000;

export const checkUpdates = async function () {
    try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
            await Updates.fetchUpdateAsync();
            setTimeout(() => {
                Alert.alert(
                    'Actualización disponible\n\n',
                    'Pulse OK para actualizar la aplicación.\n',
                    [
                        { text: 'OK', onPress: () => { Updates.reloadAsync(); } }
                    ],
                    { cancelable: false }
                )
            }, alertTimeout);
        }
    } catch (error) { }
}

const getHost = function (callback) {
    return callback("https://api.etexa.com:8247");
    const URLGetPublicIP = "https://api.ipify.org?format=json";
    fetch(URLGetPublicIP, {
        method: "GET",
        body: '',
        headers: {},
        timeout: timeout,
        retries: 0
    }).then((response) => {
        return response.json();
    }).then((ip) => {
        console.warn(ip);
        console.warn(ip && ip.ip && ip.ip == "62.82.49.19");
        if (ip && ip.ip && ip.ip == "62.82.49.19") {
            callback("http://soa.etexa.es:8280");
        } else {
            callback("https://api.etexa.com");
        }
    }).catch((error) => {
        console.warn("Error GETPUBLICIP: " + error);
        callback("http://soa.etexa.es:8280");
    });
}

const getToken = async function (callback, callbackError) {
    const token = '567c8e4b-4916-3af2-807e-2731938ba931'; //'567c8e4b-4916-3af2-807e-2731938ba931'
    const tokenExp = '';
    return callback(token);
    try {
        const value = await SecureStore.getItemAsync('tokenOAuth');
        if (value !== null) {
            token = value;
        }
    } catch (error) { }
    try {
        const value = await SecureStore.getItemAsync('tokenExp');
        if (value !== null) {
            tokenExp = value;
        }
    } catch (error) { }

    if (token != '' && tokenExp != '') {
        if (isTokenValid(tokenExp)) {
            callback(token);
        } else {
            requestToken(async function (respuesta) {
                if (respuesta && respuesta.access_token && respuesta.expires_in) {
                    try {
                        await SecureStore.setItemAsync('tokenOAuth', respuesta.access_token);
                    } catch (error) { }
                    try {
                        await SecureStore.setItemAsync('tokenExp', "" + (Date.now() + parseInt(respuesta.expires_in)));
                    } catch (error) { }
                    callback(respuesta.access_token);
                } else {
                    callbackError(respuesta);
                }
            }, function (error) {
                callbackError(error);
            });
        }
    } else {
        requestToken(function (respuesta) {
            callback(respuesta.access_token);
        }, function (error) {
            callbackError(error);
        })
    }
}

const isTokenValid = function (expiration) {
    return (Date.now() < parseInt(expiration));
}

const requestToken = async function (callback, callbackError) {
    const url = '/token';
    let clientKey = '';
    let clientSecret = '';
    try {
        const value = await SecureStore.getItemAsync('clientKey');
        if (value !== null) {
            clientKey = value;
        }
    } catch (error) { }
    try {
        const value = await SecureStore.getItemAsync('clientSecret');
        if (value !== null) {
            clientSecret = value;
        }
    } catch (error) { }
    clientKey = '8y5yPGvmff8MNVmLJomtHqOkWcIa';
    clientSecret = 'JFdzFZ1Ky1sJZNBVpvdDM2ys0K0a';
    const bytes = utf8.encode(clientKey + ':' + clientSecret);
    const authorization = base64.encode(bytes);

    const body = {
        "grant_type": "client_credentials"
    }
    let formBody = [];
    for (const property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch('https://10.0.101.101:8247' + url, {
        method: 'POST',
        body: formBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + authorization
        },
        timeout: timeout,
        retries: 0,
    }).then((response) =>
        response.json()
    ).then((responseJson) => {
        console.warn("respuesta REQUESTTOKEN: " + JSON.stringify(responseJson));
        callback(responseJson);
    }).catch((error) => {
        console.warn("error REQUESTTOKEN: " + error);
        callbackError(error);
    })
}

const getIp = function (callback) {
    const URLGetIP = "http://ip-api.com/json";
    fetch(URLGetIP, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
        },
        timeout: timeout,
        retries: 1
    }).then((response) => {
        return response.json();
    }).then((responseJson) => {
        //console.warn("Respuesta GETIP: " + JSON.stringify(responseJson))
        callback(responseJson);
    }).catch((error) => {
        console.warn("Error GETIP: " + error);
        callback("error");
    });
}

const getPhoneInfo = function (info) {
    if (info == "phoneVersion") {
        let version = Platform.Version;
        if (!isNaN(version)) {
            version = parseInt(Platform.Version, 10)
        }
        return version;
    }
    if (info == "version") {
        return Constants.manifest.version;
    }
    if (info == "phone") {
        if (Platform.OS === 'ios') {
            return Constants.platform.ios.model;
        }
        return Constants.deviceName;
    }
}

export var sendWebhook = sendWebhook = function (mensaje) {
    const url = "https://capix.akra-ti.com/php/capix.php";
    if (Platform.OS == "web") {
        let json = "{";
        if (mensaje) {
            json = json.concat('"mensaje":"', mensaje, '",');
        }
        const phoneVersion = getPhoneInfo("phoneVersion");
        if (phoneVersion) {
            json = json.concat('"phoneVersion":"', phoneVersion, '",');
        }
        const version = getPhoneInfo("version");
        if (version) {
            json = json.concat('"version":"', version, '",');
        }
        const phone = getPhoneInfo("phone");
        if (getPhoneInfo("phone")) {
            json = json.concat('"phone":"', phone, '",');
        }

        let user = '';
        const value = localStorage.getItem('user');
        if (value !== null) {
            user = String(value);
        }
        json = json.concat('"user":"', user, '"');
        json = json.concat("}");

        return fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            body: json,
            timeout: timeout,
            retries: 1
        }).then((response) => {
            //console.warn(response);
        }).catch((error) => {
            //console.warn(error);
        });
    } else {
        getIp(async function (response) {
            let formData = new FormData();
            if (response && response != "" && response != "error") {
                formData.append("ip", JSON.stringify(response));
            }
            if (mensaje)
                formData.append("mensaje", mensaje);
            if (getPhoneInfo("phoneVersion"))
                formData.append("phoneVersion", getPhoneInfo("phoneVersion"));
            if (getPhoneInfo("version"))
                formData.append("version", getPhoneInfo("version"));
            if (getPhoneInfo("phone"))
                formData.append("phone", getPhoneInfo("phone"));

            let user = '';
            try {
                const value = await SecureStore.getItemAsync('user');
                if (value !== null) {
                    user = value;
                }
            } catch (error) { }
            formData.append("user", user);

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: formData,
                timeout: timeout,
                retries: 0
            }).then((response) => {
                //console.warn(response);
            }).catch((error) => {
                //console.warn(error);
            });
        });
    }
}

export const getGroups = function (user, pass, callback, callbackError) {
    getHost(function (host) {
        const url = host + '/capix/users/name/' + user + '/' + pass;
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: 0
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta GETGROUPS: " + JSON.stringify(responseJson));
                //sendWebhook(":arrow_right: Iniciando sesión");
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error GETGROUPS: " + error);
                sendWebhook(":x: Error Iniciando sesión (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            callbackError(error);
        });
    });
}

export const getServices = function (groupId, callback, callbackError) {
    getHost(function (host) {
        const url = host + '/capix/services/all/' + groupId;
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: 0
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta GETSERVICES: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error GETSERVICES: " + error);
                sendWebhook(":x: Error Listando Servicios (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            callbackError(error);
        });
    });
}

export const getService = function (serviceId, callback, callbackError) {
    getHost(function (host) {
        const url = host + '/capix/services/' + serviceId;
        getToken(function (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: 0
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("Respuesta GETSERVICE: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("Error GETSERVICE: " + error);
                sendWebhook(":x: Error Recibiendo Servicio (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            callbackError(error);
        });
    });
}

export const executeService = function (method, urlPost, callback, callbackError, test) {
    getHost(function (host) {
        let url = urlPost;
        if (!test) {
            url = urlPost.replace("https://api.etexa.com", host);
            sendWebhook(":white_check_mark: Confirmando el servicio: " + url);
        }

        getToken(function (token) {
            fetch(url, {
                method: method,
                body: '',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: 0
            }).then((response) => {
                return Promise.all([response.json(), response.status]);
            }).then(([responseJson, status]) => {
                console.warn("Respuesta EXECUTE: " + JSON.stringify(responseJson));
                callback(responseJson, status);
            }).catch((error) => {
                console.warn("Error EXECUTE: " + error);
                if (!test) {
                    sendWebhook(":x: Error EXECUTE (" + url + "): " + error);
                }
                callbackError(error);
            });
        }, function (error) {
            callbackError(error);
        });
    });
}

export const putService = function (userId, serviceId, request, answer, httpStatus, status, callback, callbackError) {
    getHost(function (host) {
        const url = host + '/capix/services/put';
        if ((typeof answer === 'string' || answer instanceof String) && answer.toLowerCase() == "aborted") {
            //sendWebhook(":negative_squared_cross_mark: Abortando el servicio: " + url);
        }
        getToken(function (token) {
            const body = '{"user_id":' + userId +
                ',"service_id":' + serviceId +
                ',"service_request":"' + request +
                '","service_answer":"' + JSON.stringify(answer).replace(/[\"]/g, '\\"') +
                '","http_status":"' + httpStatus +
                '","status":"' + status + '"}';
            console.warn(body);
            fetch(url, {
                method: 'PUT',
                body: body,
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                timeout: timeout,
                retries: 0
            }).then((response) => {
                return response.json();
            }).then((responseJson) => {
                console.warn("respuesta PUT: " + JSON.stringify(responseJson));
                callback(responseJson);
            }).catch((error) => {
                console.warn("error PUT: " + error);
                sendWebhook(":x: Error PUT (" + url + "): " + error);
                callbackError(error);
            });
        }, function (error) {
            callbackError(error);
        });
    });
}

export const ModalLarry = class ModalLarry extends Component {
    render() {
        let modal = <View>{this.props.children}</View>;
        if (Platform.OS != "web") {
            modal = <Modal {...this.props}>{this.props.children}</Modal>;
        } else {
            modal = <ModalWEB {...this.props} appElement={document.getElementById('root')}>{this.props.children}</ModalWEB>;
        }
        return modal;
    }
}

export const grupoTest = {
    id: "testPruebas",
    descripcion: 'Pruebas Conexión (2)',
    name: "Pruebas Conexión"
}

export const serviciosTest = [
    {
        key: "1",
        id: "1",
        descripcion: "Conexión con www.akra-ti.com"
    },
    {
        key: "2",
        id: "2",
        descripcion: "Conexión con www.etexa.es"
    }
];

export const servicioTest = {
    "1": {
        "service_id": "1",
        "group_id": grupoTest.id,
        "service_name": "Conectar con AKRA (accesible desde internet)",
        "service_description": "Conectar con AKRA (accesible desde internet)",
        "service_xml": "ewogICAicmVwaXRlYWJsZSI6ICJ0cnVlIiwKICAgInVyaV90ZW1wbGF0ZSI6ICJodHRwOi8vYWtyYS10aS5jb20vY2FwaXgvdGVzdEFjY2Vzby5waHA/e3Npbm99IiwKICAgImF1dGhlbnRpY2F0aW9uIjogbnVsbCwKICAgImhlYWRlcnMiOiBudWxsLAogICAib2tfaHR0cF9zdGF0dXMiOiBbCiAgICAgICIyMDAiLAogICAgICAiMjAxIgogICBdLAogICAibWV0aG9kIjogInB1dCIsCiAgICJvdXRfcGFyYW1zIjogW3sKICAgICAgInBhcmFtIjogewogICAgICAgICAiaWQiOiAic2lubyIsCiAgICAgICAgICJzaG9ydF9kZXNjcmlwdGlvbiI6ICJQYXJhbWV0cm8iLAogICAgICAgICAibG9uZ19kZXNjcmlwdGlvbiI6ICJQYXJhbWV0cm8iLAogICAgICAgICAidHlwZSI6ICJzdHJpbmciLAogICAgICAgICAic3VidHlwZSI6ICJ1YyIsCiAgICAgICAgICJsZW5ndGgiOiAiMiIsCiAgICAgICAgICJ2YWx1ZXMiOiBbIHsKICAgICAgICAgICAgInZhbHVlIiA6ICJTSSIKICAgICAgICAgICAgfSx7CiAgICAgICAgICAgICJ2YWx1ZSIgOiAiTk8iCiAgICAgICAgICAgIH0KICAgICAgICAgXSwKICAgICAgICAgInZpc2libGUiOiAidHJ1ZSIsCiAgICAgICAgICJ3cml0ZWFibGUiOiAidHJ1ZSIsCiAgICAgICAgICJyZXF1aXJlZCI6ICJ0cnVlIgogICAgICB9CiAgIH1dLAogICAiaW5fcGFyYW1zIjogbnVsbAp9",
        "service_document": "PGh0bWw+CjxoZWFkPgo8dGl0bGU+IERvY3VtZW50byBkZSBwcnVlYmE8L3RpdGxlPgo8L2hlYWQ+Cjxib2R5IHN0eWxlPSJmb250LXNpemU6IDI1cHg7Ij4KPGJyPjxiPkVsZW1lbnRvIDE6IDwvYj5lbGVtZW50byBkZSBwcnVlYmEgMTwvYnI+Cjxicj48Yj5FbGVtZW50byAyOiA8L2I+ZWxlbWVudG8gZGUgcHJ1ZWJhIDI8L2JyPgo8YnI+PGI+RWxlbWVudG8gMzogPC9iPmVsZW1lbnRvIGRlIHBydWViYSAzPC9icj4KPGJyPiA8L2JyPgo8cHJlPjxiPkNvbDEgICAgIENvbDIgQ29sMwotLS0tLS0tLS0gLS0gLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLTwvYj4KMSAgICAgICAgICAyICAgICAgICAgIDMgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKPC9wcmU+CjwvYm9keT4KPC9odG1sPg==",
        "is_repeatable": "YES",
        "status": "Pending",
        "created_on": "2020-04-07T19:05:53.000+01:00",
        "accessed_counter": 0,
        "accessed_on": {
            "@nil": "true"
        },
        "finished_on": {
            "@nil": "true"
        }
    },
    "2": {
        "service_id": "2",
        "group_id": grupoTest.id,
        "service_name": "Conectar con Etexa (solo accesible desde dentro de la red privada)",
        "service_description": "Conectar con Etexa (solo accesible desde dentro de la red privada)",
        "service_xml": "ewogICAicmVwaXRlYWJsZSI6ICJ0cnVlIiwKICAgInVyaV90ZW1wbGF0ZSI6ICJodHRwOi8vZXRleGEuZXMvY2FwaXgvdGVzdEFjY2Vzby5waHA/e3Npbm99IiwKICAgImF1dGhlbnRpY2F0aW9uIjogbnVsbCwKICAgImhlYWRlcnMiOiBudWxsLAogICAib2tfaHR0cF9zdGF0dXMiOiBbCiAgICAgICIyMDAiLAogICAgICAiMjAxIgogICBdLAogICAibWV0aG9kIjogInB1dCIsCiAgICJvdXRfcGFyYW1zIjogW3sKICAgICAgInBhcmFtIjogewogICAgICAgICAiaWQiOiAic2lubyIsCiAgICAgICAgICJzaG9ydF9kZXNjcmlwdGlvbiI6ICJQYXJhbWV0cm8iLAogICAgICAgICAibG9uZ19kZXNjcmlwdGlvbiI6ICJQYXJhbWV0cm8iLAogICAgICAgICAidHlwZSI6ICJzdHJpbmciLAogICAgICAgICAic3VidHlwZSI6ICJ1YyIsCiAgICAgICAgICJsZW5ndGgiOiAiMiIsCiAgICAgICAgICJ2YWx1ZXMiOiBbIHsKICAgICAgICAgICAgInZhbHVlIiA6ICJTSSIKICAgICAgICAgICAgfSx7CiAgICAgICAgICAgICJ2YWx1ZSIgOiAiTk8iCiAgICAgICAgICAgIH0KICAgICAgICAgXSwKICAgICAgICAgInZpc2libGUiOiAidHJ1ZSIsCiAgICAgICAgICJ3cml0ZWFibGUiOiAidHJ1ZSIsCiAgICAgICAgICJyZXF1aXJlZCI6ICJ0cnVlIgogICAgICB9CiAgIH1dLAogICAiaW5fcGFyYW1zIjogbnVsbAp9",
        "service_document": "PGh0bWw+CjxoZWFkPgo8dGl0bGU+IERvY3VtZW50byBkZSBwcnVlYmE8L3RpdGxlPgo8L2hlYWQ+Cjxib2R5IHN0eWxlPSJmb250LXNpemU6IDI1cHg7Ij4KPGJyPjxiPkVsZW1lbnRvIDE6IDwvYj5lbGVtZW50byBkZSBwcnVlYmEgMTwvYnI+Cjxicj48Yj5FbGVtZW50byAyOiA8L2I+ZWxlbWVudG8gZGUgcHJ1ZWJhIDI8L2JyPgo8YnI+PGI+RWxlbWVudG8gMzogPC9iPmVsZW1lbnRvIGRlIHBydWViYSAzPC9icj4KPGJyPiA8L2JyPgo8cHJlPjxiPkNvbDEgICAgIENvbDIgQ29sMwotLS0tLS0tLS0gLS0gLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLTwvYj4KMSAgICAgICAgICAyICAgICAgICAgIDMgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAKPC9wcmU+CjwvYm9keT4KPC9odG1sPg==",
        "is_repeatable": "YES",
        "status": "Pending",
        "created_on": "2020-04-07T19:05:53.000+01:00",
        "accessed_counter": 0,
        "accessed_on": {
            "@nil": "true"
        },
        "finished_on": {
            "@nil": "true"
        }
    }
};

export const alertTimeout = (Platform.OS.toLocaleLowerCase() === 'android' || Platform.OS.toLocaleLowerCase() === 'web' ? 0 : 550);

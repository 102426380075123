import React from "react";
import {
    Text,
    View,
    FlatList,
    StyleSheet,
    TouchableOpacity,
    BackHandler
} from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Notifications } from "expo";
import { getServices, getService, alertTimeout, grupoTest, serviciosTest, servicioTest } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ListaServicios extends React.PureComponent {
    _didFocusSubscription;
    _willBlurSubscription;

    constructor(props) {
        super(props);

        const { navigation } = this.props;
        const idGrupo = navigation.getParam('idGrupo', '-1');
        const arrayServicios = navigation.getParam('arrayServicios', []);
        let datos = this.getDatos(arrayServicios);
        this.state = {
            datos: datos,
            isFetching: false,
            idGrupo: idGrupo,
            spinner: false,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
        this._didFocusSubscription = props.navigation.addListener('didFocus', payload => {
            BackHandler.addEventListener('hardwareBackPress', this.onBackButtonPressAndroid)
        });
    }

    componentWillUnmount() {
        this._didFocusSubscription && this._didFocusSubscription.remove();
        this._willBlurSubscription && this._willBlurSubscription.remove();
        this._notificationSubscription && this._notificationSubscription.remove();
    }

    componentDidMount() {
        this._willBlurSubscription = this.props.navigation.addListener('willBlur', payload =>
            BackHandler.removeEventListener('hardwareBackPress', this.onBackButtonPressAndroid)
        );
        this._notificationSubscription = Notifications.addListener(this._handleNotification);

        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            ),
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { this._onRefresh(); }} >
                    <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
    }

    _handleNotification = (notification) => {
        if (this.props.isFocused) {
            this._onRefresh();
        }
    }

    onBackButtonPressAndroid = () => {
        this.props.navigation.state.params.refresh();
    }

    goBack = () => {
        this.onBackButtonPressAndroid();
        this.props.navigation.goBack();
    }

    _onPressItem = (id) => {
        if (this.state.idGrupo == grupoTest.id) {
            const servicio = servicioTest[id];
            this.props.navigation.navigate('Servicio', {
                servicio: servicio,
                refresh: this._onRefresh
            });
        } else if (id && id != '' && id >= 0) {
            const myThis = this;
            this.setSpinnerVisible(true);
            getService(id, function (respuesta) {
                myThis.setSpinnerVisible(false);
                if (respuesta.servicesCollection && respuesta.servicesCollection.services
                    && respuesta.servicesCollection.services[0].service_xml) {
                    myThis.props.navigation.navigate('Servicio', {
                        servicio: respuesta.servicesCollection.services[0],
                        refresh: myThis._onRefresh
                    });
                } else {
                    setTimeout(() => {
                        myThis.setError({
                            title: "Error",
                            message: "Error en la comunicación con el servidor (-301)",
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "Reintentar",
                                onPress: () => { myThis.limpiarError(); myThis._onPressItem(id); }
                            },
                            positiveButton: {
                                title: "OK",
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: "Error",
                        message: "Error en la comunicación con el servidor (-302)",
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: "Reintentar",
                            onPress: () => { myThis.limpiarError(); myThis._onPressItem(id); }
                        },
                        positiveButton: {
                            title: "OK",
                            onPress: () => { myThis.limpiarError(); }
                        }
                    });
                }, alertTimeout);
            });
        }
    }

    _onRefresh = () => {
        if (this.state.idGrupo != grupoTest.id) {
            let myThis = this;
            myThis.setState({ isFetching: true }, function () {
                getServices(myThis.state.idGrupo, function (respuesta) {
                    if (respuesta.servicesCollection && respuesta.servicesCollection.servicesDes) {
                        let datos = myThis.getDatos(respuesta.servicesCollection.servicesDes);
                        myThis.setState({
                            datos: datos,
                            isFetching: false
                        });
                    } else if (respuesta.API_Status && respuesta.API_Status.Request_Status &&
                        respuesta.API_Status.Request_Status.includes("Group has no services.")) {
                        myThis.setState({
                            datos: myThis.getDatos([]),
                            isFetching: false
                        });
                    } else {
                        myThis.setState({
                            datos: myThis.getDatos([]),
                            isFetching: false
                        });
                        setTimeout(() => {
                            myThis.setError({
                                title: "Error",
                                message: "Error en la comunicación con el servidor (-303)",
                                visible: true,
                                onTouchOutside: () => { },
                                negativeButton: {
                                    title: "Reintentar",
                                    onPress: () => { myThis.limpiarError(); myThis._onRefresh(); }
                                },
                                positiveButton: {
                                    title: "OK",
                                    onPress: () => { myThis.limpiarError(); }
                                }
                            });
                        }, alertTimeout);
                    };
                }, function (error) {
                    myThis.setState({
                        datos: myThis.getDatos([]),
                        isFetching: false
                    });
                    setTimeout(() => {
                        myThis.setError({
                            title: "Error",
                            message: "Error en la comunicación con el servidor (-304)",
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "Reintentar",
                                onPress: () => { myThis.limpiarError(); myThis._onRefresh(); }
                            },
                            positiveButton: {
                                title: "OK",
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                });
            });
        }
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    getDatos = (arrayServicios) => {
        let datos = [];
        if (this.props.navigation.getParam('idGrupo', '-1') == grupoTest.id) {
            datos = [...serviciosTest];
        } else {
            if (arrayServicios.length > 0) {
                for (let i = 0; i < arrayServicios.length; i++) {
                    datos.push({
                        key: "" + i,
                        id: arrayServicios[i].service_id,
                        descripcion: arrayServicios[i].service_name
                    });
                }
            } else {
                datos.push({
                    key: '0',
                    descripcion: 'No tienes elementos pendientes!'
                });
            }
        }
        return datos;
    }

    _renderItem = ({ item }) => {
        return (
            <View style={styles.fila} key={item.key}>
                <ListItem
                    key={item.key}
                    id={item.id}
                    title={item.descripcion}
                    onPressItem={this._onPressItem}
                />
            </View>
        );
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    style={styles.container}
                    data={this.state.datos}
                    renderItem={this._renderItem}
                    onRefresh={this._onRefresh}
                    refreshing={this.state.isFetching}
                />
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => <TouchableOpacity style={{ paddingHorizontal: 15 }}>
            <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
        </TouchableOpacity>;
        const auxHeaderRight = () => <TouchableOpacity style={{ paddingHorizontal: 15 }}>
            <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
        </TouchableOpacity>;
        return {
            title: navigation.getParam("title", "Servicio"),
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft),
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

class ListItem extends React.PureComponent {
    _onPress = () => {
        this.props.onPressItem(this.props.id);
    }

    render() {
        return (
            <TouchableOpacity onPress={this._onPress} style={styles.touchable}>
                <View>
                    <Text style={styles.textoFila}>
                        {this.props.title}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    fila: {
        //width: '100%',
        backgroundColor: '#ff8c8c',
        //borderWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#ff8c8c',
        borderBottomColor: '#fff'
    },
    textoFila: {
        fontSize: 20,
        color: '#fff'
    },
    touchable: {
        paddingBottom: 15,
        paddingTop: 15,
        paddingLeft: 15
    }
});

export default withNavigationFocus(ListaServicios);

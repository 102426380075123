import React from "react";
import {
    Text,
    View,
    FlatList,
    StyleSheet,
    TouchableOpacity,
    Platform,
} from "react-native";
import Spinner from "react-native-loading-spinner-overlay";
import { withNavigationFocus } from "react-navigation";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Notifications } from "expo";
import { getGroups, getServices, alertTimeout, checkUpdates, grupoTest } from "../Utility/Api";
import { ConfirmDialog } from 'react-native-simple-dialogs';

class ListaGrupos extends React.PureComponent {
    constructor(props) {
        super(props);

        const { navigation } = this.props;
        const arrayGrupos = navigation.getParam('arrayGrupos', []);
        const user = navigation.getParam('user', '');
        const password = navigation.getParam('password', '');
        let datos = this.getDatos(arrayGrupos);

        this.state = {
            datos: datos,
            isFetching: false,
            user: user,
            password: password,
            spinner: false,
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        };
    }

    componentDidMount() {
        checkUpdates();
        this._notificationSubscription = Notifications.addListener(this._handleNotification);

        this.props.navigation.setParams({
            headerLeft: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { this.goBack(); }} >
                    <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
                </TouchableOpacity>
            ),
            headerRight: () => (
                <TouchableOpacity
                    style={{ paddingHorizontal: 15 }}
                    onPress={() => { this._onRefresh(); }} >
                    <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
                </TouchableOpacity>
            )
        });
    }

    componentWillUnmount() {
        this._notificationSubscription && this._notificationSubscription.remove();
    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    _handleNotification = (notification) => {
        if (this.props.isFocused) {
            this._onRefresh();
        }
    }

    setSpinnerVisible = (visible) => {
        this.setState({
            spinner: visible
        });
    }

    _onPressItem = (id, title) => {
        if (id == grupoTest.id) {
            this.props.navigation.navigate('ListaServicios', {
                idGrupo: id,
                arrayServicios: [],
                refresh: this._onRefresh,
                title: title
            });
        } else if (id && id != '' && id >= 0) {
            let myThis = this;
            this.setSpinnerVisible(true);
            getServices(id, function (respuesta) {
                myThis.setSpinnerVisible(false);
                if (respuesta.servicesCollection && respuesta.servicesCollection.servicesDes) {
                    myThis.props.navigation.navigate('ListaServicios', {
                        idGrupo: id,
                        arrayServicios: respuesta.servicesCollection.servicesDes,
                        refresh: myThis._onRefresh,
                        title: title
                    });
                } else if (respuesta.API_Status && respuesta.API_Status.Request_Status &&
                    respuesta.API_Status.Request_Status.includes("Group has no services.")) {
                    myThis.props.navigation.navigate('ListaServicios', {
                        idGrupo: id,
                        arrayServicios: [],
                        refresh: myThis._onRefresh,
                        title: title
                    });
                } else {
                    setTimeout(() => {
                        myThis.setError({
                            title: "Error",
                            message: "Error en la comunicación con el servidor (-201)",
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "Reintentar",
                                onPress: () => { myThis.limpiarError(); myThis._onPressItem(id, title); }
                            },
                            positiveButton: {
                                title: "OK",
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                }
            }, function (error) {
                myThis.setSpinnerVisible(false);
                setTimeout(() => {
                    myThis.setError({
                        title: "Error",
                        message: "Error en la comunicación con el servidor (-202)",
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: "Reintentar",
                            onPress: () => { myThis.limpiarError(); myThis._onPressItem(id, title); }
                        },
                        positiveButton: {
                            title: "OK",
                            onPress: () => { myThis.limpiarError(); }
                        }
                    });
                }, alertTimeout);
            });
        }
    }

    _onRefresh = () => {
        let myThis = this;
        checkUpdates();
        myThis.setState({ isFetching: true }, function () {
            getGroups(myThis.state.user, myThis.state.password, function (respuesta) {
                if (respuesta.capix && respuesta.capix.groupusersCollection && respuesta.capix.groupusersCollection.groupusers) {
                    let datos = myThis.getDatos(respuesta.capix.groupusersCollection.groupusers);
                    myThis.setState({
                        datos: datos,
                        isFetching: false
                    });
                } else if (respuesta.API_Status && respuesta.API_Status.Request_Status &&
                    respuesta.API_Status.Request_Status.includes("User has no groups.")) {
                    myThis.setState({
                        datos: myThis.getDatos([]),
                        isFetching: false
                    });
                } else {
                    myThis.setState({
                        datos: myThis.getDatos([]),
                        isFetching: false
                    });
                    setTimeout(() => {
                        myThis.setError({
                            title: "Error",
                            message: "Error en la comunicación con el servidor (-203)",
                            visible: true,
                            onTouchOutside: () => { },
                            negativeButton: {
                                title: "Reintentar",
                                onPress: () => { myThis.limpiarError(); myThis._onRefresh(); }
                            },
                            positiveButton: {
                                title: "OK",
                                onPress: () => { myThis.limpiarError(); }
                            }
                        });
                    }, alertTimeout);
                }
            }, function (error) {
                myThis.setState({
                    datos: myThis.getDatos([]),
                    isFetching: false,
                });
                setTimeout(() => {
                    myThis.setError({
                        title: "Error",
                        message: "Error en la comunicación con el servidor (-204)",
                        visible: true,
                        onTouchOutside: () => { },
                        negativeButton: {
                            title: "Reintentar",
                            onPress: () => { myThis.limpiarError(); myThis._onRefresh(); }
                        },
                        positiveButton: {
                            title: "OK",
                            onPress: () => { myThis.limpiarError(); }
                        }
                    });
                }, alertTimeout);
            });
        });
    }

    getDatos(arrayGrupos) {
        let datos = [];
        let totalServicios = 0;
        if (arrayGrupos.length > 0) {
            for (let i = 0; i < arrayGrupos.length; i++) {
                const name = arrayGrupos[i].group_name.charAt(0).toUpperCase() + arrayGrupos[i].group_name.slice(1);
                const descripcion = name + ' (' + arrayGrupos[i].services_count + ')';
                datos.push({
                    key: "" + i,
                    id: arrayGrupos[i].group_id,
                    descripcion: descripcion,
                    name: name
                });
                //TODO este grupoFixed debería recibirlo de la api para cada grupo
                let grupoFixed = false;
                if (arrayGrupos[i].group_name.toLowerCase() == "telecomando-fabrica") {
                    grupoFixed = true;
                }
                if (!grupoFixed) {
                    totalServicios += arrayGrupos[i].services_count;
                }
            }
        }
        datos.push({
            key: "" + arrayGrupos.length,
            id: grupoTest.id,
            descripcion: grupoTest.descripcion,
            name: grupoTest.name
        });
        //TODO Los servicios que se repiten, contaran tambien para notificaciones, el servidor podria pasarme otra variable quitando esas
        if (Platform.OS.toLocaleLowerCase() === 'ios') {
            Notifications.setBadgeNumberAsync(totalServicios);
        }
        return datos;
    }

    _renderItem = ({ item }) => {
        return (
            <View style={styles.fila} key={item.key}>
                <ListItem
                    key={item.key}
                    id={item.id}
                    title={item.descripcion}
                    name={item.name}
                    onPressItem={this._onPressItem}
                />
            </View>
        );
    }

    setError = (error) => {
        this.setState({
            dialogError: error
        });
    }

    limpiarError = () => {
        this.setState({
            dialogError: {
                title: "",
                message: "",
                visible: false,
                onTouchOutside: () => { },
                negativeButton: {
                    title: "",
                    onPress: () => { }
                },
                positiveButton: {
                    title: "",
                    onPress: () => { }
                }
            }
        });
    }

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    style={styles.container}
                    data={this.state.datos}
                    renderItem={this._renderItem}
                    onRefresh={this._onRefresh}
                    refreshing={this.state.isFetching}
                />
                <ConfirmDialog
                    title={this.state.dialogError.title}
                    message={this.state.dialogError.message}
                    visible={this.state.dialogError.visible}
                    onTouchOutside={this.state.dialogError.onTouchOutside}
                    negativeButton={this.state.dialogError.negativeButton}
                    positiveButton={this.state.dialogError.positiveButton}
                />
                <Spinner visible={this.state.spinner} textContent={"Cargando..."} textStyle={{ color: '#FFF' }} />
            </View>
        );
    }

    static navigationOptions = ({ navigation }) => {
        const auxHeaderLeft = () => <TouchableOpacity style={{ paddingHorizontal: 15 }}>
            <MaterialCommunityIcons name={"arrow-left"} size={30} color="#fff" />
        </TouchableOpacity>;
        const auxHeaderRight = () => <TouchableOpacity style={{ paddingHorizontal: 15 }}>
            <MaterialCommunityIcons name={"reload"} size={30} color="#fff" />
        </TouchableOpacity>;
        return {
            title: 'Grupos pendientes',
            headerLeft: navigation.getParam("headerLeft", auxHeaderLeft),
            headerRight: navigation.getParam("headerRight", auxHeaderRight)
        };
    }
}

class ListItem extends React.PureComponent {
    _onPress = () => {
        this.props.onPressItem(this.props.id, this.props.name);
    }

    render() {
        return (
            <TouchableOpacity onPress={this._onPress} style={styles.touchable}>
                <View>
                    <Text style={styles.textoFila}>
                        {this.props.title}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff'
    },
    fila: {
        backgroundColor: '#ff8c8c',
        borderBottomWidth: 1,
        borderColor: '#ff8c8c',
        borderBottomColor: '#fff'
    },
    textoFila: {
        fontSize: 20,
        color: '#fff'
    },
    touchable: {
        paddingBottom: 15,
        paddingTop: 15,
        paddingLeft: 15
    }
});

export default withNavigationFocus(ListaGrupos);
